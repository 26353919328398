import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { rescindLiveOffers, makeOffer } from '../../api/interactionService';
import { useActionMessage } from '../common/actionMessage/ActionMessage';
import { formatToDateInput } from '../utils/formatTimestamps';

interface ReviewFormProps {
  ownerId: string;
  bidderId: string;
  leaseId: string;
  chatId: string;
  currentOffer: any;
  callBackToChat: () => void;
}

const ReviewForm = ({
  ownerId,
  bidderId,
  leaseId,
  chatId,
  currentOffer,
  callBackToChat,
}: ReviewFormProps) => {
  const [startDate, setStartDate] = useState(
    formatToDateInput(currentOffer?.start_date) || ''
  );
  const [endDate, setEndDate] = useState(
    formatToDateInput(currentOffer?.end_date) || ''
  );
  const [price, setPrice] = useState(currentOffer?.price || '');
  const [priceUnit, setPriceUnit] = useState(
    currentOffer?.price_unit || 'Total'
  );
  const [numBeds, setNumBeds] = useState(currentOffer?.num_beds_for_bid || '');
  const [loading, setLoading] = useState(false);
  const { showMessage } = useActionMessage();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    // Submit the bid
    try {
      // Construct the bid data
      const bidData = {
        owner_id: ownerId || '',
        bidder_id: bidderId || '',
        lease_id: leaseId || '',
        chat_id: chatId || '',
        start_date: startDate,
        end_date: endDate,
        price: parseFloat(price),
        price_unit: priceUnit,
        num_beds_for_bid: parseInt(numBeds.toString(), 10),
        is_live: true,
      };

      // Submit the bid using the service function
      const success = await makeOffer(bidData);
      if (success) {
        callBackToChat();
        showMessage('Offer is now live.', 'success');
      }
    } catch (error: any) {
      showMessage(`Error: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handlerescindLiveOffers = async () => {
    try {
      setLoading(true);
      // Submit the bid using the service function
      const success = await rescindLiveOffers(
        chatId,
        ownerId,
        bidderId,
        leaseId
      );
      if (success) {
        // Show success message and return to chat
        showMessage(success.message, 'success');
        callBackToChat(); // Move back to chat after rescinding
      } else {
        // If rescinding failed, show a message
        showMessage('Failed to rescind the offer. Please try again.', 'error');
      }
    } catch (error: any) {
      // Handle unexpected errors and display the message
      showMessage(
        `Error: ${error.message || 'Unknown error occurred.'}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography
        sx={{
          p: 1,
          textAlign: 'center',
          fontStyle: 'italic',
        }}
      >
        By submitting this offer, it becomes{' '}
        <Typography
          component="span"
          sx={{
            fontWeight: 'bold',
            color: 'green',
          }}
        >
          live
        </Typography>
        . The user can accept the offer and proceed to payment. 'Rescind Offer'
        will remove any live offer. User can only proceed to payment if there is
        a{' '}
        <Typography
          component="span"
          sx={{
            fontWeight: 'bold',
            color: 'green',
          }}
        >
          live{' '}
        </Typography>
        bid.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled={loading}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={7}>
              <TextField
                label="Price"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Unit Price"
                select
                value={priceUnit}
                onChange={(e) => setPriceUnit(e.target.value)}
                fullWidth
                disabled={loading}
              >
                <MenuItem value="day">Per day</MenuItem>
                <MenuItem value="week">Per week</MenuItem>
                <MenuItem value="month">Per month</MenuItem>
                <MenuItem value="total">Total</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Number of Beds"
            type="number"
            value={numBeds}
            onChange={(e) => setNumBeds(e.target.value)}
            fullWidth
            disabled={loading}
          />
        </Grid>
      </Grid>

      {/* Buttons Row */}
      <Grid container spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Grid item>
          <Button
            onClick={callBackToChat}
            variant="text"
            color="inherit"
            disabled={loading}
            size="small"
          >
            {loading ? <CircularProgress size={24} /> : 'Cancel'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handlerescindLiveOffers}
            variant="outlined"
            color="inherit"
            disabled={loading}
            size="small"
          >
            {loading ? <CircularProgress size={24} /> : 'Rescind Offer'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            size="small" // Make the buttons small
          >
            {loading ? <CircularProgress size={24} /> : 'Submit Bid'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewForm;
