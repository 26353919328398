import { useEffect, useState } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { Lease } from '../types/Lease';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import { formatDate } from '../utils/formatTimestamps';
import { getBidsOnLease } from '../../api/interactionService';
import BidCard from './BidCard';
// Define the props type
interface IncomingBidsProps {
  lease: Lease;
  goToChat: (chatId: string, ownerId: string, bidderId: string) => void;
}

// Component starts here
const IncomingBids = ({ lease, goToChat }: IncomingBidsProps) => {
  const [user] = useAuthState(auth);
  const [ownerInteractions, setOwnerInteractions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch bids for the specific lease and user details for each bid
  useEffect(() => {
    console.log('Fetching bids for leases:', lease);
    const loadBids = async () => {
      try {
        setLoading(true);
        if (lease && lease.owner_id && lease.id && user) {
          // Fetch the bids using the service function
          const interactions = await getBidsOnLease(lease.owner_id, lease.id);
          setOwnerInteractions(interactions);
        }
      } catch (err) {
        console.error('Error fetching bids:', err);
      } finally {
        setLoading(false);
      }
    };

    loadBids();
  }, [lease, user]);

  if (loading) {
    // Display a few skeletons to simulate loading cards
    return (
      <Box>
        {[...Array(3)].map((_, index) => (
          <Skeleton
            animation="wave"
            key={index}
            variant="rectangular"
            height={80}
            sx={{
              borderRadius: '8px',
              mb: 2,
            }}
          />
        ))}
      </Box>
    );
  }

  if (ownerInteractions.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        No bids have been placed on this lease yet.
      </Typography>
    );
  }

  return (
    <Box>
      {ownerInteractions.map((data) => (
        <BidCard
          key={data.bid_id}
          bidderName={data.bidder_first_name}
          bidderProfilePic={data.bidder_profile_picture_url}
          lastMessage={data.most_recent_message}
          mostRecentBid={`Current Offer: $${data.price}/${data.price_unit}, ${
            data.num_beds_for_bid
          } bed(s), ${formatDate(data.start_date)} - ${formatDate(
            data.end_date
          )}`}
          timestamp={data.most_recent_message_timestamp}
          onClick={() =>
            goToChat(
              data.most_recent_message_chat_id,
              data.owner_id,
              data.bidder_id
            )
          } // Use the passed function to navigate to chat
        />
      ))}
    </Box>
  );
};

export default IncomingBids;
