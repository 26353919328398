import React from 'react';
import { Card, CardContent, Typography, Button, Tooltip } from '@mui/material';
import { VerifiedUser } from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Lease } from '../types/Lease';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../theme/global.css';
import { auth } from '../../config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';
interface LeaseCardProps {
  lease: Lease;
  handleViewDetails: (lease: Lease) => void;
}

const LeaseCard = ({ lease, handleViewDetails }: LeaseCardProps) => {
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short', // This will abbreviate the month (e.g., "Oct" for October)
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [user] = useAuthState(auth);

  return (
    <Card
      raised
      sx={{
        width: '95%',
        overflow: 'hidden',
        borderRadius: '20px',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(24px)',
        boxShadow: 5,
        borderColor: 'divider',
      }}
    >
      <div style={{ position: 'relative' }}>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop
          style={{ width: '100%', height: '250px' }}
        >
          {lease.image_urls && lease.image_urls.length > 0 ? (
            lease.image_urls.map((image, idx) => (
              <SwiperSlide key={idx}>
                <img
                  src={image}
                  alt={`Lease ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px 10px 0 0',
                  }}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src="/No_Image_Available.jpg"
                alt="User uploaded 0 images"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px 10px 0 0',
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <CardContent
        sx={{ padding: '16px' }}
        onClick={() => handleViewDetails(lease)}
      >
        <Typography variant="h6" color="primary" gutterBottom noWrap>
          {lease.address.split(',')[0]}
          {lease.verified && (
            <Tooltip title="User is verified">
              <VerifiedUser
                sx={{
                  color: '#1976d2',
                  fontSize: '1.3rem', // Smaller size
                  verticalAlign: 'text-top', // Align with the text
                  ml: 1,
                }}
              />
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {lease.address.split(',').slice(1).join(', ')}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {formatDate(lease.start_date)} - {formatDate(lease.end_date)}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {lease.num_rooms_for_lease} Bedroom(s) -{' '}
          <span style={{ fontWeight: 'bold' }}>${lease.price}</span> /{' '}
          {lease.price_unit}
        </Typography>
      </CardContent>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '0px 15px 5px 5px',
        }}
      >
        {lease.owner_id === (user?.uid || '') ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleViewDetails(lease)}
          >
            Check Your Post
          </Button>
        ) : (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => handleViewDetails(lease)}
          >
            View Details
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default LeaseCard;
