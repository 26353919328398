import React from "react";
import { FormControlLabel, Checkbox, Tooltip, Link, Grid } from "@mui/material";

interface TermsAndConditionsProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  checked,
  onChange,
}) => {
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name="termsAndConditionsCheckBox"
          />
        }
        label={
          <Tooltip
            title="By Checking The Box, You Understand That Subleasing is Strictly
            Prohibited Should Your Lease Agreement Not Allow It, And That
            You Are Liable For Any Legal Consequences That May Arise From
            Subleasing Without Permission."
            placement="right"
            arrow
            enterDelay={500}
            enterNextDelay={500}
          >
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                console.log("Terms and Conditions clicked");
              }}
              style={{ cursor: "pointer" }}
            >
              I agree to the Terms and Conditions
            </Link>
          </Tooltip>
        }
      />
    </Grid>
  );
};

export default TermsAndConditions;
