import {
  Grid,
  Box,
  Typography,
  Divider,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { auth } from '../../../config/firebase-config';
import { Lease } from '../../types/Lease';
import { Profile } from '../../types/Profile';
import { brand } from '../../../theme/theme';
import { VerifiedUser } from '@mui/icons-material';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { getUserProfile } from '../../../api/userService';

interface UserDetailsTabProps {
  lease: Lease;
}

const UserDetailsTab = ({ lease }: UserDetailsTabProps) => {
  const [profileData, setProfileData] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [user] = useAuthState(auth);
  // const [signInWithGoogle] = useSignInWithGoogle(auth);

  useEffect(() => {
    const loadProfileData = async () => {
      if (lease && lease.owner_id) {
        try {
          setLoading(true);
          const data = await getUserProfile(lease.owner_id);
          console.log('Profile data:', data);
          setProfileData(data);
        } catch (error) {
          console.error('Error fetching profile data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadProfileData();
  }, [lease]);

  if (loading || !profileData) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={300}
        sx={{ borderRadius: '8px' }}
      />
    );
  }

  const {
    first_name,
    university,
    year,
    other_year,
    gender,
    major,
    housing_gender_preference,
    housing_gender_preference_other,
    reveal_info,
  } = profileData;

  // Determine what to display based on user and reveal_info status
  const displayedName = !user
    ? 'Sign In Required'
    : reveal_info
    ? first_name
    : 'Not revealed';

  const displayedUniversity = !user
    ? 'Sign In Required'
    : reveal_info
    ? university
    : 'Not revealed';

  const displayedGender = !user
    ? 'Sign In Required'
    : reveal_info
    ? gender
    : 'Not revealed';

  const displayedMajor = !user
    ? 'Sign In Required'
    : reveal_info
    ? major
    : 'Not revealed';

  const displayedYear = !user
    ? 'Sign In Required'
    : reveal_info
    ? other_year || year
    : 'Not revealed';

  const displayedHousingPreference = !user
    ? 'Sign In Required'
    : reveal_info
    ? housing_gender_preference_other || housing_gender_preference
    : 'Not revealed';

  return (
    <Box sx={{ mt: 2, p: 2, bgcolor: '#EAF0F5', borderRadius: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: brand[400] }} gutterBottom>
            User Details
            {lease.verified && (
              <Tooltip title="User is verified">
                <VerifiedUser
                  sx={{
                    color: '#1976d2',
                    fontSize: '1.3rem', // Smaller size
                    verticalAlign: 'text-top', // Align with the text
                    ml: 1,
                  }}
                />
              </Tooltip>
            )}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                sx={{ color: brand[500] }}
                gutterBottom
              >
                Name
              </Typography>
              <Typography variant="body1" gutterBottom>
                {displayedName}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ color: brand[500] }}
                gutterBottom
              >
                University
              </Typography>
              <Typography variant="body1" gutterBottom>
                {displayedUniversity}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ color: brand[500] }}
                gutterBottom
              >
                Year in School
              </Typography>
              <Typography variant="body1" gutterBottom>
                {displayedYear}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ color: brand[500] }}
                gutterBottom
              >
                Major
              </Typography>
              <Typography variant="body1" gutterBottom>
                {displayedMajor}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                sx={{ color: brand[500] }}
                gutterBottom
              >
                Gender
              </Typography>
              <Typography variant="body1" gutterBottom>
                {displayedGender || 'Not revealed'}
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ color: brand[500] }}
                gutterBottom
              >
                Housing Gender Preference
              </Typography>
              <Typography variant="body1" gutterBottom>
                {displayedHousingPreference}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetailsTab;
