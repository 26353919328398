import React, { useState } from 'react';
import { Box, Typography, Link, Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme, { brand } from '../../../theme/theme';
import PostForm from '../../dubleases/form/PostForm';
import TermsModal from './sections/TermsConditionsModal'; // Import TermsModal

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const [PostFormModalOpen, setPostFormModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false); // State for Terms modal
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return null;
  }

  const handlePostFormModalOpen = () => {
    setPostFormModalOpen(true);
  };

  const handlePostFormModalClose = () => {
    setPostFormModalOpen(false);
  };

  const handleTermsModalOpen = () => {
    setTermsModalOpen(true);
  };

  const handleTermsModalClose = () => {
    setTermsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'fixed', // Make the footer fixed
          bottom: 0, // Align it to the bottom
          left: 0, // Align it to the left edge
          width: '100%', // Span the full width
          backgroundColor: brand[500],
          padding: 3,
          textAlign: 'center',
          color: 'white',
          zIndex: 1000, // Ensure it stays on top of other elements
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            mb: 2,
            flexDirection: { xs: 'column', md: 'row' },
            paddingBottom: { xs: 2, md: 0 },
          }}
        >
          <Link
            href="#"
            onClick={() => navigate('/FAQS&ContactUs')}
            sx={{
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              mb: { xs: 1, md: 0 },
            }}
          >
            FAQs & Contact Us
          </Link>
          <Link
            href="#"
            onClick={() => navigate('/AboutUs')}
            sx={{
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              mb: { xs: 1, md: 0 },
            }}
          >
            About
          </Link>
          <Link
            onClick={handleTermsModalOpen} // Open the modal for Terms
            sx={{
              color: 'white',
              textDecoration: 'none',
              fontSize: '1rem',
              mb: { xs: 1, md: 0 },
            }}
          >
            Terms & Conditions
          </Link>
          <Button
            onClick={handlePostFormModalOpen}
            sx={{
              fontSize: '1rem',
              color: 'white',
              textTransform: 'none',
              backgroundColor: 'transparent',
              lineHeight: '1.5',
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Make A Post
          </Button>
        </Box>
        <Typography
          variant="body2"
          sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}
        >
          &copy; {new Date().getFullYear()} Dublease. All rights reserved.
        </Typography>
      </Box>

      {/* Modals */}
      <PostForm
        open={PostFormModalOpen}
        handleClose={handlePostFormModalClose}
      />
      <TermsModal open={termsModalOpen} handleClose={handleTermsModalClose} />
    </>
  );
};

export default Footer;
