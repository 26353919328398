export function loadGoogleMapsApi() {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  if (apiKey) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
    script.async = true;
    document.head.appendChild(script);
  } else {
    console.error('Google API key not found');
  }
}
