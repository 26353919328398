// OutgoingBids.tsx
import React, { useEffect, useState } from 'react';
import { Typography, Box, Skeleton } from '@mui/material';
import BidCard from './BidCard';
import { getBidderLeases } from '../../api/interactionService';
import { formatDate } from '../utils/formatTimestamps';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';

interface OutgoingBidsProps {
  goToLease: (lease: any, leaseId: string) => void;
}

const OutgoingBids: React.FC<OutgoingBidsProps> = ({ goToLease }) => {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [bidderLeases, setBidderLeases] = useState([]);

  useEffect(() => {
    const fetchBidderLeases = async () => {
      if (!user) return;
      try {
        setLoading(true);
        const leasesBidOn = await getBidderLeases(user.uid);
        setBidderLeases(leasesBidOn);
      } catch (err) {
        console.error('Error fetching bidder leases:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBidderLeases();
  }, [user]);

  if (loading) {
    // Display a few skeletons to simulate loading cards
    return (
      <Box>
        {[...Array(3)].map((_, index) => (
          <Skeleton
            animation="wave"
            key={index}
            variant="rectangular"
            height={80}
            sx={{
              borderRadius: '8px',
              mb: 2,
            }}
          />
        ))}
      </Box>
    );
  }

  return (
    <Box>
      {bidderLeases.length === 0 && (
        <Typography>No outgoing bids made.</Typography>
      )}
      {bidderLeases.map((data: any) => (
        <BidCard
          key={data.lease_id}
          bidderName={data.owner_first_name}
          bidderProfilePic={data.owner_profile_picture_url}
          lastMessage={data.most_recent_message}
          mostRecentBid={`Current Offer: $${data.price}/${data.price_unit}, ${
            data.num_beds_for_bid
          } bed(s), ${formatDate(data.start_date)} - ${formatDate(
            data.end_date
          )}`}
          timestamp={data.most_recent_message_timestamp}
          onClick={() => goToLease(data, data.lease_id)}
        />
      ))}
    </Box>
  );
};

export default OutgoingBids;
