import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { brand } from '../../../theme/theme';

interface PersonalInformationProps {
  profileData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  profileData,
  handleChange,
  handleCheckboxChange,
}) => (
  <>
    <Typography
      id="modal-title"
      variant="h6"
      sx={{ color: brand[500] }}
      component="h2"
      gutterBottom
    >
      Personal Information
    </Typography>
    <Typography
      id="modal-description"
      variant="subtitle1"
      color="textSecondary"
      sx={{ mb: 2, fontStyle: 'italic' }}
      gutterBottom
    >
      Only your first name and profile picture will be visible to other users by
      default
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Email"
          name="email"
          value={profileData.email}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="First Name"
          name="first_name"
          value={profileData.first_name}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Last Name"
          name="last_name"
          value={profileData.last_name}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Phone Number"
          name="phone_number"
          value={profileData.phone_number}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Student ID"
          name="student_id"
          value={profileData.student_id}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography
          id="modal-description"
          variant="subtitle1"
          color="textSecondary"
          sx={{ fontStyle: 'italic' }}
          gutterBottom
        >
          We send Notifications on updates to your lease, bids, chats, payments
          and more. We will never share your contact information with anyone.
          You can change your preferences at any time.
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" sx={{ mr: 2, fontWeight: 600 }}>
            Notifications preference:
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.email_notifications_on || false}
                  onChange={handleCheckboxChange}
                  name="email_notifications_on"
                  color="primary"
                />
              }
              label="Email"
              sx={{ mr: 2 }} // Add margin to separate checkboxes
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={profileData.text_notifications_on || false}
                  onChange={handleCheckboxChange}
                  name="text_notifications_on"
                  color="primary"
                />
              }
              label="SMS Message"
            />
          </FormGroup>
        </Box>
      </Grid>
    </Grid>
  </>
);

export default PersonalInformation;
