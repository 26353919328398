// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { brand } from './theme/theme';

import Navbar from './components/layout/navbar/Navbar';
import Footer from './components/layout/footer/Footer';
import AboutUs from './components/layout/footer/sections/AboutUs';
import FAQsContactUs from './components/layout/footer/sections/FAQsContactUs';
import LeaseDisplay from './components/dubleases/LeaseDisplay';
import { ActionMessageProvider } from './components/common/actionMessage/ActionMessage';
import { loadGoogleMapsApi } from './components/utils/LoadGoogleMapsAPI';

const App: React.FC = () => {
  const [splashVisible, setSplashVisible] = useState(true);

  // TODO pre-fetch data (cache) for the LeaseDisplay component
  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3000); // 2 seconds delay

    // Load Google Maps API
    loadGoogleMapsApi();

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <ActionMessageProvider>
        {splashVisible ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{
                duration: 1,
                delay: 0.5,
                ease: 'easeInOut',
              }}
              style={{ textAlign: 'center' }}
            >
              <Typography
                variant="h1"
                sx={{ fontWeight: 'bold', mb: 2, color: brand[500] }}
                component={motion.h1}
              >
                dublease
              </Typography>
              <Typography variant="h3" color="inherit">
                subleasing for college students
              </Typography>
            </motion.div>
          </Box>
        ) : (
          <>
          
            <Navbar />
            <Routes>
              <Route
                path="/"
                element={
                  <Box sx={{ p: 5, pt: 20 }}>
                    <LeaseDisplay />
                  </Box>
                }
              />
              <Route path="/FAQS&ContactUs" element={<FAQsContactUs />} />
              <Route path="/AboutUs" element={<AboutUs />} />
            </Routes>
            <Footer />
          </>
        )}
      </ActionMessageProvider>
    </Router>
  );
};

export default App;
