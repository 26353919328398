import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Tabs, Tab, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import LeaseDetailsModal from '../dubleases/LeaseDetailsModal';
import OutgoingBids from '../bids/OutgoingBids';
import AllIncomingBids from '../bids/AllIncomingBids';

interface InboxModalProps {
  open: boolean;
  handleClose: () => void;
}

const InboxModal = ({ open, handleClose }: InboxModalProps) => {
  const [user] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [tabIndex, setTabIndex] = useState(0);
  const [leaseId, setLeaseId] = useState('');
  const [openLeaseDetails, setOpenLeaseDetails] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const goToLease = (lease: any, lease_id: string) => {
    console.log('Going to lease:', lease);
    handleClose();
    setLeaseId(lease_id);
    setOpenLeaseDetails(true);
  };
  const closeLeaseDetails = () => {
    setOpenLeaseDetails(false);
  };

  useEffect(() => {
    if (!user) {
      signInWithGoogle();
      return;
    }

    // Fetch data for notifications
  }, [open, signInWithGoogle, user]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="lease-details-title"
      >
        <Box
          sx={{
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'divider',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
            maxHeight: '90vh', // Maximum height
            overflowY: 'auto', // Allow vertical scrolling
            bgcolor: 'background.paper',
            boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
            p: 4,
            outline: 0, // Remove default focus outline
          }}
        >
          {/* X Close Button */}
          <IconButton
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 600,
              fontSize: 24,
              color: 'primary.main',
              marginBottom: 2,
            }}
          >
            Your Inbox
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="Lease details tabs"
            sx={{ marginBottom: 2 }}
          >
            <Tab sx={{ fontSize: '14px' }} label="Notifications" />
            <Tab sx={{ fontSize: '14px' }} label="Outgoing Bids" />
            <Tab sx={{ fontSize: '14px' }} label="Incoming Bids" />
          </Tabs>

          {tabIndex === 1 && <OutgoingBids goToLease={goToLease} />}
          {tabIndex === 2 && <AllIncomingBids goToLease={goToLease} />}
        </Box>
      </Modal>
      {openLeaseDetails && (
        <LeaseDetailsModal
          open={openLeaseDetails}
          handleClose={closeLeaseDetails}
          leaseId={leaseId}
          defaultTabIndex={0} // Pass tab index
        />
      )}
    </>
  );
};

export default InboxModal;
