import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Lease } from '../../types/Lease';
import { brand } from '../../../theme/theme';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase-config';
import PostForm from '../form/PostForm';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDeleteDialog';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';

interface LeaseDetailsTabProps {
  lease: Lease;
}

const LeaseDetailsTab = ({ lease }: LeaseDetailsTabProps) => {
  const [user] = useAuthState(auth);
  const { showMessage } = useActionMessage();
  const navigate = useNavigate();
  const [postFormModalOpen, setPostFormModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  // Delete the post and close the dialog
  const handleDeleteConfirm = async () => {
    if (lease && user && user?.uid === lease.owner_id) {
      // Get the Firebase ID token from the current user
      const idToken = await user.getIdToken();
      try {
        const response = await fetch(
          `http://localhost:5000/api/posts/delete/${lease.id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        const result = await response.json();
        showMessage(result.message, 'success');
        navigate('/');
      } catch (error) {
        console.error('Error deleting post:', error);
        showMessage('Error deleting post. Please try again.', 'error');
      }
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <>
      <Box>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={0}
          slidesPerView={1}
          loop
          style={{ width: '100%', height: '400px' }} // Adjust height as needed
        >
          {lease.image_urls && lease.image_urls.length > 0 ? (
            lease.image_urls.map((image, idx) => (
              <SwiperSlide key={idx}>
                <img
                  src={image}
                  alt={`Lease ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: '10px 10px 0 0',
                  }}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img
                src="/No_Image_Available.jpg"
                alt="User uploaded 0 images"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px 10px 0 0',
                }}
              />
            </SwiperSlide>
          )}
        </Swiper>

        <Box sx={{ mt: 2, p: 2, bgcolor: '#EAF0F5', borderRadius: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[400] }} gutterBottom>
                Lease Details
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Start Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(lease.start_date)}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Price
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    ${lease.price} / {lease.price_unit}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Total Property Bedrooms
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_property_bedrooms}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Furnished
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.furnished ? 'Yes' : 'No'}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Utilities Included
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.utilities ? 'Yes' : 'No'}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    End Date
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatDate(lease.end_date)}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Rooms for Lease
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_rooms_for_lease} Bedroom(s)
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Bathrooms
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_bathrooms}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Parking Options
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.parking_option}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Pets Allowed
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.pets_allowed ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: brand[400] }} gutterBottom>
                Additional Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Roommates Aware
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.are_roommates_aware ? 'Yes' : 'No'}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    House Gender
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.housing_gender}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: brand[500] }}
                    gutterBottom
                  >
                    Roommates Present During Sublease
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {lease.num_roommates_present_during_dublease}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: brand[500] }}
                  gutterBottom
                >
                  Description
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {lease.description
                    ? lease.description
                    : 'No Description Provided'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {lease.owner_id === user?.uid && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: '0px 0px 0px 0px',
                gap: '10px',
              }}
            >
              <Button
                variant="text"
                color="inherit"
                size="large"
                onClick={() => setDeleteDialogOpen(true)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  setPostFormModalOpen(true);
                }}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <PostForm
        open={postFormModalOpen}
        handleClose={() => setPostFormModalOpen(false)}
        post={lease}
      />
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Are you sure you want to delete this post? Deleting the post will delete all bids, offers, and chats."
        confirmText="Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default LeaseDetailsTab;
