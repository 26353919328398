import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Divider,
  Link,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { brand } from '../../../../theme/theme';
import { useNavigate } from 'react-router-dom';

const AboutUs: React.FC = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/'); // Navigate back to homepage
  };
  return (
    <Box sx={{ mt: 10, mx: 'auto', maxWidth: '900px', padding: 4 }}>
      <Button startIcon={<ArrowBackIcon />} onClick={handleGoBack}>
        Go Back
      </Button>
      {/* Mission Statement */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          color: brand[500],
          mb: '30px',
        }}
      >
        Mission Statement
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center', mb: 4 }}>
        Our mission is to simplify life for college students with secure and
        flexible subleasing solutions that support their unique circumstances.
      </Typography>

      <Divider sx={{ m: 4 }} />
      {/* Meet the Founders */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          color: brand[500],
          mb: '30px',
        }}
      >
        Meet the Founders!
      </Typography>
      {/* Founder Section */}
      <Grid container spacing={6} sx={{ alignItems: 'center' }}>
        {/* Lais Najjar */}
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <Avatar
            alt="Lais Najjar"
            src="/Najjar_Lais_Headshot.JPG" // Replace with actual path to image
            sx={{ width: 180, height: 180, mb: 2, mx: 'auto' }}
          />
          <Typography variant="h5" fontWeight="bold">
            Lais Najjar, Co-founder
            <Link
              href="https://linkedin.com/in/laisnajjar"
              target="_blank"
              rel="noopener"
            >
              <img
                src="/LI-In-Bug.png"
                alt="LinkedIn"
                style={{ marginLeft: 8, width: 26, height: 'auto' }} // Adjust size and margin
              />
            </Link>
          </Typography>
          <Typography variant="body1" gutterBottom>
            University of Michigan (&#39;25)
            <br />
            B.S. Computer Science
            <br />
            Varsity Gymnastics Team Captain
            <br />
            Olympian, Paris 2024
            <br />
            1st Gen American, Son of Syrian Immigrants
            <br />
            Never seen in the same room as Houdini
          </Typography>
        </Grid>

        {/* Alan Gerdov */}
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <Avatar
            alt="Alan Gerdov"
            src="/Gerdov_Alan_Headshot.JPG" // Replace with actual path to image
            sx={{ width: 180, height: 180, mb: 2, mx: 'auto' }}
          />
          <Typography variant="h5" fontWeight="bold">
            Alan Gerdov, Co-founder
            <Link
              href="https://linkedin.com/in/alangerdov"
              target="_blank"
              rel="noopener"
            >
              <img
                src="/LI-In-Bug.png"
                alt="LinkedIn"
                style={{ marginLeft: 8, width: 26, height: 'auto' }} // Adjust size and margin
              />
            </Link>
          </Typography>
          <Typography variant="body1" gutterBottom>
            University of Michigan (&#39;22)
            <br />
            M.S.E. & B.S.E. Electrical Engineering
            <br />
            Former Varsity Gymnastics Team Captain
            <br />
            Product Manager at Microsoft, Passed PE Exam
            <br />
            1st Gen American, Son of Ukrainian Immigrants
            <br />
            Recently dunked a basketball
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ m: 4 }} />

      {/* Company Values */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          color: brand[500],
          mb: '30px',
        }}
      >
        Company Values
      </Typography>

      {/* Values */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Honesty
        </Typography>
        <Typography variant="body1" paragraph>
          We know it’s hard to commit to a year-long lease when you don’t know
          where life is going to pull you. We also know it’s hard to find
          subleasing options you trust. These are real problems that we have
          too, and we want to help figure it out for all of us. To do that, we
          need everyone to be honest, so we can work with the right people and
          find the solutions that best serve your unique circumstances.
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Trust
        </Typography>
        <Typography variant="body1" paragraph>
          There are two types of relationships being built here: one between
          users and one between each user and us as a platform. Those
          relationships need trust for this to work, so we can have safe and
          fair subleasing agreements. We’re committed to earning your trust as
          facilitators, so that we can facilitate secure transactions and
          provide all of us peace of mind.
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          Excellence
        </Typography>
        <Typography variant="body1" paragraph>
          We are committed to excellence in everything we do, including this
          subleasing platform for college students. We’re always looking for
          ways to better our website - and consequently ourselves - so we can
          better serve you. We want to hear your feedback on how to make this
          experience more comfortable, simpler, and faster, so we can deliver
          that to you, and hear the next feedback.
        </Typography>
      </Box>

      <Divider sx={{ m: 4 }} />

      {/* How did you two start Dublease? */}
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          color: brand[500],
          mb: '30px',
        }}
      >
        How did you two start Dublease?
      </Typography>
      <Typography variant="body1" paragraph>
        Lais and I are proud University of Michigan grads and best friends.
        Despite being four years apart, we were blessed with one year as
        teammates on Michigan’s varsity gymnastics team in 2021. Since then,
        we’ve been looking for any opportunity to be teammates again…
      </Typography>
      <Typography variant="body1" paragraph>
        We found it.
      </Typography>
      <Typography variant="body1" paragraph>
        Lais brought up the silly but real problem faced by countless college
        students worldwide: subleasing. As a senior, he noticed that the mgym
        incoming freshmen were excited to come to Ann Arbor early, but there
        wasn’t any obvious way to connect them with the many students with empty
        rooms for the summer.
      </Typography>
      <Typography variant="body1" paragraph>
        Why wasn’t subleasing available on a centralized platform?
      </Typography>
      <Typography variant="body1" paragraph>
        It is now. Dublease.
      </Typography>
      <Typography variant="body1" paragraph>
        Realizing we could provide immediate value, we created a solution
        starting at our alma mater… Go blue baby!
      </Typography>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="body1" paragraph>
          - Alan “I wish this existed when I was in college” Gerdov
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <img
          src="/Alan_Lais_Roar.jpg"
          alt="Alan and Lais Roar"
          style={{ width: '50%', height: 'auto' }} // Adjust size and margin
        />
      </Box>
      <Divider sx={{ m: 4 }} />

      <Grid
        container
        spacing={6}
        sx={{ textAlign: 'center', justifyContent: 'center', mb: 30 }}
      >
        {/* Lais's Links */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Lais Najjar
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Link
              href="https://www.michigandaily.com/sports/gymnastics/you-raised-our-heads-lais-najjars-journey-to-the-olympics/#google_vignette"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              &#39;You raised our heads&#39;: Lais Najjar&#39;s journey to the
              Olympics
            </Link>
            <Link
              href="https://cse.engin.umich.edu/stories/a-dream-fulfilled-cs-major-lais-najjar-competes-in-paris-olympics"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              A dream fulfilled: CS major Lais Najjar competes in Paris Olympics
            </Link>
            <Link
              href="https://en.wikipedia.org/wiki/Lais_Najjar"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              Lais Najjar Profile - Wikipedia
            </Link>
          </Box>
        </Grid>

        {/* Alan's Links */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Alan Gerdov
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Link
              href="https://www.youtube.com/watch?v=n9UDcN6ygX4&t=2s"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              Alan Gerdov is a Real Life Success Story
            </Link>
            <Link
              href="https://ece.engin.umich.edu/stories/scholar-stories-gerdov-overcomes-adversity-injury-to-pave-way-at-michigan"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              Scholar Stories: Gerdov Overcomes Adversity, Injury to Pave Way at
              Michigan
            </Link>
            <Link
              href="https://www.detroitnews.com/story/sports/college/university-michigan/2023/06/11/michigan-gymnast-alan-gerdov-shirts-children-ukraine/70303164007/"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              For former Michigan gymnast, shirts have special meaning, special
              cause
            </Link>
            <Link
              href="https://www.detroitnews.com/restricted/?return=https%3A%2F%2Fwww.detroitnews.com%2Fstory%2Fsports%2Fcollege%2Funiversity-michigan%2F2022%2F02%2F25%2Fukrainian-michigan-grad-and-gymnast-stands-strong-natives-back-home%2F6937080001%2F"
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              Ukrainian Michigan grad, gymnast stands strong with natives back
              home
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
