// React
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Box, Typography, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// Firebase
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
// Components
import PersonalInformation from './sections/PersonalInformation';
import AccountVerification from './sections/AccountVerification';
import OptionalInformation from './sections/OptionalInformation';
import ProfileActions from './sections/ProfileActions';
// API Services
import {
  getCurrentUserProfile,
  updateUserProfile,
  uploadUserImage,
} from '../../api/userService';
// Types
import { Profile } from '../types/Profile';
// Theme
import { brand } from '../../theme/theme';
import { useActionMessage } from '../common/actionMessage/ActionMessage';

function ProfileModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const [user] = useAuthState(auth);
  const { showMessage } = useActionMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileData, setProfileData] = useState<Profile>({
    first_name: '',
    last_name: '',
    email: user?.email || '',
    phone_number: '',
    student_id: '',
    university: 'University of Michigan',
    year: '',
    other_year: '',
    gender: '',
    major: '',
    housing_gender_preference: '',
    housing_gender_preference_other: '',
    reveal_info: false,
    profile_picture_url: '',
    student_id_picture_url: '',
    email_notifications_on: true,
    text_notifications_on: true,
  });
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [studentIdPicture, setStudentIdPicture] = useState<string | null>(null);
  const [profilePictureLoading, setProfilePictureLoading] = useState(false);
  const [studentIdPictureLoading, setStudentIdPictureLoading] = useState(false);

  // Load existing profile data from Firestore on modal open
  useEffect(() => {
    const loadProfile = async () => {
      if (user) {
        try {
          const data = await getCurrentUserProfile();
          setProfileData((prev) => ({
            ...prev,
            ...data,
            email: data?.email || user.email || '',
            university: data?.university || 'University of Michigan',
          }));
          setProfilePicture(data.profile_picture_url || '');
          setStudentIdPicture(data.student_id_picture_url || '');
        } catch (error) {
          console.error('Error loading profile:', error);
        }
      }
    };
    loadProfile();
  }, [user]);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  // Handle switch changes
  const handleRevealInfoSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileData({ ...profileData, reveal_info: e.target.checked });
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Handle profile picture upload
  const handleProfilePictureUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setProfilePictureLoading(true);
      try {
        const imageUrl = await uploadUserImage(file, 'profilePicture');
        console.log('Profile picture uploaded:', imageUrl);
        console.log('Profile data:', profileData);
        setProfilePicture(imageUrl);
        setProfileData((prev) => ({
          ...prev,
          profile_picture_url: imageUrl,
        }));

        console.log(profileData);
      } catch (error) {
        console.error('Error uploading profile picture:', error);
      } finally {
        setProfilePictureLoading(false);
      }
    }
  };

  // Handle student ID upload
  const handleStudentIdUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setStudentIdPictureLoading(true);
      console.log('Uploading student ID picture...');
      try {
        const imageUrl = await uploadUserImage(file, 'studentIdPicture');
        setStudentIdPicture(imageUrl);
        setProfileData((prev) => ({
          ...prev,
          student_id_picture_url: imageUrl,
        }));
        console.log(profileData);
      } catch (error) {
        console.error('Error uploading student ID picture:', error);
      } finally {
        setStudentIdPictureLoading(false);
      }
    }
  };

  // Save profile data
  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await updateUserProfile(profileData);
      showMessage('Profile saved successfully!', 'info');
      handleClose();
    } catch (error) {
      console.error('Error saving profile:', error);
      showMessage('Error saving profile. Please try again.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' },
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`,
          p: 4,
          outline: 0,
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-title"
          variant="h5"
          sx={{ color: brand[400] }}
          component="h2"
          gutterBottom
        >
          Profile
        </Typography>
        <Divider variant="fullWidth" sx={{ mb: 2 }} />

        {/* Personal Information Section */}
        <PersonalInformation
          profileData={profileData}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
        />

        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

        {/* Account Verification Section */}
        <AccountVerification
          profilePicture={profilePicture}
          studentIdPicture={studentIdPicture}
          handleProfilePictureUpload={handleProfilePictureUpload}
          handleStudentIdUpload={handleStudentIdUpload}
          profilePictureLoading={profilePictureLoading}
          studentIdPictureLoading={studentIdPictureLoading}
        />

        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

        {/* Optional Information Section */}
        <OptionalInformation
          profileData={profileData}
          handleSwitchChange={handleRevealInfoSwitch}
          handleChange={handleChange}
        />

        {/* Save and Close Actions */}
        <ProfileActions
          isSubmitting={isSubmitting}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      </Box>
    </Modal>
  );
}

export default ProfileModal;
