/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  Typography,
} from '@mui/material';
import { formatToDateInput } from '../utils/formatTimestamps';
import { auth } from '../../config/firebase-config';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { Lease } from '../types/Lease';
import ProfileModal from '../profile/Profile';
import { useActionMessage } from '../common/actionMessage/ActionMessage';
import { checkProfileCompletion } from '../../api/userService';
import { getBid, saveInitialInteraction } from '../../api/interactionService';
import Chat from '../chat/Chat';

interface PlaceBidTabProps {
  lease: Lease;
  goToChat: (chatId: string, ownerId: string, bidderId: string) => void;
}

const PlaceBidTab = ({ lease, goToChat }: PlaceBidTabProps) => {
  const [user] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [price, setPrice] = useState('');
  const [priceUnit, setPriceUnit] = useState('');
  const [numBeds, setNumBeds] = useState(0);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [chatId, setChatId] = useState('');
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isExistingBid, setIsExistingBid] = useState(false);
  const { showMessage } = useActionMessage();

  useEffect(() => {
    // if (!user) {
    //   signInWithGoogle();
    //   return;
    // }

    const loadUserBidData = async () => {
      if (
        lease &&
        user &&
        lease.owner_id &&
        lease.id &&
        lease.owner_id !== user.uid
      ) {
        try {
          const bid = await getBid(lease.owner_id, user.uid, lease.id);
          console.log(
            'owner_id:',
            lease.owner_id,
            'user_id:',
            user.uid,
            'lease_id:',
            lease.id
          );
          console.log('Bid:', bid);
          if (bid) {
            setIsExistingBid(true);
            setChatId(bid.chat_id);
            // Set the bid data if it exists
            setStartDate(formatToDateInput(bid.start_date));
            setEndDate(formatToDateInput(bid.end_date));
            setPrice(bid.price.toString());
            setPriceUnit(bid.price_unit);
            setNumBeds(bid.num_beds_for_bid.toString());
            goToChat(bid.chat_id, lease.owner_id, user.uid);
          } else {
            // Set default values from lease if no bid exists
            setStartDate(
              lease.start_date ? formatToDateInput(lease.start_date) : ''
            );
            setEndDate(lease.end_date ? formatToDateInput(lease.end_date) : '');
            setPrice(lease.price ? lease.price.toString() : '');
            setPriceUnit(lease.price_unit || '');
            setNumBeds(lease.num_rooms_for_lease || 0);
          }
        } catch (error) {
          console.error('Error loading bid data:', error);
        }
      }
    };

    loadUserBidData();
  }, [user, signInWithGoogle, lease, goToChat]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    // Check if the user's profile is complete
    const isProfileComplete = await checkProfileCompletion();
    if (!isProfileComplete) {
      setProfileModalOpen(true);
      setLoading(false);
      return;
    }

    try {
      // Construct the bid data
      const bidData = {
        owner_id: lease.owner_id || '',
        bidder_id: user?.uid || '',
        lease_id: lease.id || '',
        start_date: startDate,
        end_date: endDate,
        price: parseFloat(price),
        price_unit: priceUnit,
        num_beds_for_bid: parseInt(numBeds.toString(), 10),
        additional_details: additionalDetails,
      };

      // Submit the bid using the service function
      const chat_id = await saveInitialInteraction(bidData);

      if (chat_id) {
        goToChat(chat_id, lease?.owner_id || '', user?.uid || '');
        setIsExistingBid(true);
        showMessage('Bid submitted successfully!', 'success');
      } else {
        throw new Error('Failed to submit bid');
      }
    } catch (error: any) {
      showMessage(`Error: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleProfileModalClose = () => {
    setProfileModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* Conditionally render chat or place bid form */}
      {isExistingBid ? (
        // Show chat if bid exists
        <Chat
          chatId={chatId}
          ownerId={lease?.owner_id || ''}
          bidderId={user?.uid || ''}
          leaseId={lease?.id || ''}
        />
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <TextField
                    label="Price"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    fullWidth
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Unit Price"
                    select
                    value={priceUnit}
                    onChange={(e) => setPriceUnit(e.target.value)}
                    fullWidth
                    disabled={loading}
                  >
                    <MenuItem value="day">Per day</MenuItem>
                    <MenuItem value="week">Per week</MenuItem>
                    <MenuItem value="month">Per month</MenuItem>
                    <MenuItem value="total">Total</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Number of Beds"
                type="number"
                value={numBeds}
                onChange={(e) => setNumBeds(Number(e.target.value))}
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>

          <TextField
            label="Additional Details"
            multiline
            rows={4}
            value={additionalDetails}
            onChange={(e) => setAdditionalDetails(e.target.value)}
            fullWidth
            disabled={loading}
          />

          <Button
            type="submit"
            variant="contained"
            color={'primary'}
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit Bid'}
          </Button>

          <ProfileModal
            open={profileModalOpen}
            handleClose={handleProfileModalClose}
          />
        </Box>
      )}
    </Box>
  );
};

export default PlaceBidTab;
