import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, Slide } from '@mui/material';
import {
  CheckCircle,
  Error as ErrorIcon,
  Warning,
  Info,
} from '@mui/icons-material';
import { green } from '@mui/material/colors';

// Function for the slide animation
const SlideTransition = (props: any) => {
  return <Slide {...props} direction="up" />;
};

interface ActionMessageContextProps {
  showMessage: (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => void;
  children?: ReactNode;
}

const ActionMessageContext = createContext<
  ActionMessageContextProps | undefined
>(undefined);

// Custom hook for accessing the context
export const useActionMessage = () => {
  const context = useContext(ActionMessageContext);
  if (!context) {
    throw new Error(
      'useActionMessage must be used within an ActionMessageProvider'
    );
  }
  return context;
};

// Function to get custom icon for each severity
const getIcon = (severity: 'success' | 'error' | 'warning' | 'info') => {
  switch (severity) {
    case 'success':
      return <CheckCircle />;
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <Warning />;
    case 'info':
      return <Info />;
    default:
      return null;
  }
};

// ActionMessageProvider component to wrap your app
export const ActionMessageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('success');
  const [open, setOpen] = useState(false);

  const showMessage = (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info'
  ) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Custom styles for each severity
  //TODO fix colors
  const getCustomStyles = () => {
    switch (severity) {
      case 'success':
        return { backgroundColor: green[600], color: '#fff' }; // Green for success
      case 'error':
        return { backgroundColor: '#f44336', color: '#fff' }; // Red for error
      case 'warning':
        return { backgroundColor: '#ff9800', color: '#fff' }; // Orange for warning
      case 'info':
        return { backgroundColor: '#2196f3', color: '#fff' }; // Blue for info
      default:
        return {};
    }
  };

  return (
    <ActionMessageContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionComponent={SlideTransition} // Slide animation
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          icon={getIcon(severity)} // Custom icons
          sx={{
            ...getCustomStyles(),
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Shadow effect
            borderRadius: '8px', // Rounded corners
            padding: '16px', // Padding for better spacing
            fontWeight: 'bold', // Bolder text
            textAlign: 'center', // Center the text
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ActionMessageContext.Provider>
  );
};
