// src/components/FAQsContactUs.tsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addDoc, collection } from 'firebase/firestore';
import { auth, db } from '../../../../config/firebase-config'; // Ensure Firebase is set up and db is imported
import { useAuthState } from 'react-firebase-hooks/auth';
import { useActionMessage } from '../../../common/actionMessage/ActionMessage';
import { useNavigate } from 'react-router-dom';

const FAQsContactUs: React.FC = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const { showMessage } = useActionMessage();
  const [form, setForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });

  useEffect(() => {
    if (user) {
      setForm((prevForm) => ({
        ...prevForm,
        email: user.email || '',
      }));
    }
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'contact-us'), {
        ...form,
        createdAt: new Date(), // Add createdAt field
      });
      showMessage(
        'Request Sent. Dublease Dubthanks you and will get back to you Dubquickly.',
        'info'
      );
      setForm({ name: '', email: '', phoneNumber: '', message: '' });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleGoBack = () => {
    navigate('/'); // Navigate back to homepage
  };

  return (
    <Box
      sx={{
        position: 'relative',
        mt: 15,
        padding: 4,
        maxWidth: '800px',
        mx: 'auto',
      }}
    >
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={handleGoBack}
        sx={{
          position: 'absolute',
          left: {
            xs: '0', // For mobile devices (xs breakpoint), keep it inside the box
            sm: '-50px', // For small screens, move it slightly outside the box
            md: '-100px', // For medium and larger screens, push it further left
          },
          mb: 10,
        }}
      >
        Go Back
      </Button>
      {/* FAQs Section */}
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        FAQs
      </Typography>
      <Box sx={{ mb: 6 }}>
        {/* Improved Accordion styling */}
        <Accordion
          sx={{
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            mb: 2,
            '&:before': { display: 'none' }, // Remove default Accordion divider line
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              '& .MuiAccordionSummary-content': {
                fontWeight: 'bold',
              },
            }}
          >
            How much are transaction fees?
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Transaction fees depend on the amount being transacted. Please
              check our detailed terms for the exact fees.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            mb: 2,
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              '& .MuiAccordionSummary-content': {
                fontWeight: 'bold',
              },
            }}
          >
            How do I know this is safe?
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Though you incur your own risk, we create a subleasing agreement
              following your transactions. Dublease is committed to safety by
              ensuring all customers apply with their school emails and matching
              school IDs for authenticity and accountability.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            mb: 2,
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              '& .MuiAccordionSummary-content': {
                fontWeight: 'bold',
              },
            }}
          >
            My lease agreement doesn’t let me sublease. What should I do?
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We’ll do our best to contact your property owner. Contact us below
              with your email and phone number and we’ll reach out to them.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            mb: 2,
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              '& .MuiAccordionSummary-content': {
                fontWeight: 'bold',
              },
            }}
          >
            Do I stay on my lease if I find someone to sublease?
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, in most cases, you remain on the lease unless otherwise
              stated in your agreement with the sublessee.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* Contact Us Section */}
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
        Contact Us
      </Typography>
      <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
        {' '}
        {/* Centering the form */}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            name="email"
            type="email"
            value={form.email}
            onChange={handleInputChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                name="name"
                value={form.name}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={handleInputChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <TextField
            label="Message"
            name="message"
            value={form.message}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />
          <Button variant="contained" type="submit" fullWidth>
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default FAQsContactUs;
