/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';
import { auth } from '../../config/firebase-config';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Grid, Pagination, Skeleton, Typography } from '@mui/material';
import { Lease } from '../types/Lease';
import LeaseDetailsModal from './LeaseDetailsModal';
import LeaseCard from './LeaseCard';
import { getLeases } from '../../api/leaseService';
import { useAuthState } from 'react-firebase-hooks/auth';
import LeaseFilter from './Filters';
import { getOwnerLeases } from '../../api/interactionService';
// import io from 'socket.io-client'; // Import socket.io-client for frontend
// const socket = io('http://localhost:5000', {
//   transports: ['websocket'], // Specify WebSocket transport if needed
//   transportOptions: {
//     websocket: {
//       pingInterval: 60000, // 30 seconds between pings
//       pingTimeout: 120000, // Wait 60 seconds for a pong response before disconnecting
//       reconnectionAttempts: 10, // Retry up to 10 reconnections
//       timeout: 40000, // 20 seconds timeout for the initial connection
//     },
//   },
// }); // Connect to the backend socket

function LeaseDisplay() {
  const [user] = useAuthState(auth);
  const [leases, setLeases] = useState<Lease[]>([]);
  const [currentLease, setCurrentLease] = useState<Lease | undefined>(
    undefined
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Filter States
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [location, setLocation] = useState<string | null>(null);
  const [numBeds, setNumBeds] = useState<number | ''>('');
  const [numRoommates, setNumRoommates] = useState<number | ''>('');
  const [sortPrice, setSortPrice] = useState<string>('asc');

  // Fetch Leases
  useEffect(() => {
    const loadLeases = async () => {
      const minimumLoadingTime = 1000; // Minimum loading time in ms (1 second)
      const startTime = Date.now();
      setLoading(true);
      try {
        //TODO refactor API call
        const filters = {
          startDate,
          endDate,
          location,
          numBeds,
          numRoommates,
          sortPrice,
        };
        const { posts, totalCount } = await getLeases(page, filters);
        const ownerLeases = await getOwnerLeases(user?.uid || '');
        setTotalPages(Math.ceil(totalCount / 12)); // assume 20 items per page

        console.log('Owner leases:', ownerLeases);
        console.log('Other leases:', posts);
        // owner leases come first
        setLeases([...ownerLeases, ...posts]);
      } catch (error) {
        console.error('Error loading leases:', error);
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = minimumLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0));
      }
    };

    // Fetch initial leases
    loadLeases();

    // socket.on('connect', () => {
    //   console.log('Socket connected:', socket.id);
    // });

    // socket.on('disconnect', (reason, details) => {
    //   // the reason of the disconnection, for example "transport error"
    //   console.log(reason);
    //   console.log(details);
    // });
    // // Subscribe to lease events from the backend
    // socket.on('lease:create', (message) => {
    //   const newLease = JSON.parse(message).data;
    //   setLeases((prevLeases) => [newLease, ...prevLeases]);
    // });

    // socket.on('lease:update', (message) => {
    //   try {
    //     const leaseData =
    //       typeof message === 'string' ? JSON.parse(message) : message;
    //     console.log('Update message:', leaseData);
    //   } catch (error) {
    //     console.error('Error parsing message:', error);
    //   }
    //   const updatedLease = JSON.parse(message).data;
    //   setLeases((prevLeases) =>
    //     prevLeases.map((lease) =>
    //       lease.id === updatedLease.id ? updatedLease : lease
    //     )
    //   );
    // });

    // socket.on('lease:delete', (message) => {
    //   console.log('message type', typeof message);
    //   console.log('Delete message:', message);
    //   const deletedLeaseId = JSON.parse(message).lease_id;
    //   setLeases((prevLeases) =>
    //     prevLeases.filter((lease) => lease.id !== deletedLeaseId)
    //   );
    // });

    // return () => {
    //   socket.off('lease:create');
    //   socket.off('lease:update');
    //   socket.off('lease:delete');
    // };
  }, [
    page,
    user,
    startDate,
    endDate,
    location,
    numBeds,
    numRoommates,
    sortPrice,
  ]);

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value); // Update page to load new set of leases
  };

  // View Details button click handler
  const handleViewDetails = (lease: Lease) => {
    setCurrentLease(lease);
    setDetailsModalOpen(true);
  };

  // Skeleton loading placeholders
  const renderSkeletons = () => (
    <Grid container spacing={2} sx={{ padding: '13px' }}>
      {[...Array(12)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2.3} key={index}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="95%"
            height={400}
            sx={{ borderRadius: '8px' }}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      {/* {renderFilterSection()} */}
      <LeaseFilter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        location={location}
        setLocation={setLocation}
        numBeds={numBeds}
        setNumBeds={setNumBeds}
        numRoommates={numRoommates}
        setNumRoommates={setNumRoommates}
        sortPrice={sortPrice}
        setSortPrice={setSortPrice}
      />
      {loading ? (
        <Grid
          container
          spacing={2}
          sx={{
            padding: '1px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {renderSkeletons()}
        </Grid>
      ) : leases.length > 0 ? (
        <Grid container spacing={2} sx={{ padding: '1px' }}>
          <AnimatePresence>
            {leases.map((lease, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2.3} // Make 5 leases appear in a row on large screens
                key={lease.id}
                style={{ paddingBottom: '20px' }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -30 }}
                  transition={{
                    duration: 0.8,
                    delay: index * 0.1,
                    ease: 'easeOut',
                  }}
                  style={{
                    width: '100%',
                    maxWidth: '345px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <LeaseCard
                    lease={lease}
                    handleViewDetails={handleViewDetails}
                  />
                </motion.div>
              </Grid>
            ))}
          </AnimatePresence>
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: 20,
          }}
        >
          <Typography variant="h4" color="textSecondary" gutterBottom>
            No leases available yet.
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Be the first to post a lease and help others find their next home!
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="center" m={2} pb={10}>
        <Pagination
          count={totalPages} // Set this to total number of pages
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
      {currentLease && (
        <LeaseDetailsModal
          open={detailsModalOpen}
          handleClose={() => setDetailsModalOpen(false)}
          leaseId={currentLease.id || ''}
        />
      )}
    </>
  );
}

export default LeaseDisplay;
