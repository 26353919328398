import React from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';

interface ProfileActionsProps {
  isSubmitting: boolean;
  handleSave: () => void;
  handleClose: () => void;
}

const ProfileActions: React.FC<ProfileActionsProps> = ({
  isSubmitting,
  handleSave,
  handleClose,
}) => (
  <Grid
    container
    spacing={2}
    mt={1}
    display="flex"
    justifyContent="space-between"
  >
    <Grid item xs={6}>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={handleClose}
        fullWidth
      >
        Cancel
      </Button>
    </Grid>
    <Grid item xs={6}>
      <Button
        type="button"
        fullWidth
        variant="contained"
        onClick={handleSave}
        disabled={isSubmitting}
      >
        {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
      </Button>
    </Grid>
  </Grid>
);

export default ProfileActions;
