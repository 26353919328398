// React
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// Firebase
import { auth } from '../../../config/firebase-config';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
// Components
import ImageUpload from './sections/ImageUpload';
import TermsAndConditions from './sections/TermsAndConditions';
import FormInputs from './sections/FormInputs';
import SubmitButton from './sections/SubmitButton';
import ConfirmationDialog from '../../common/confirmationDialog/ConfirmationDeleteDialog';

// Types
import { Lease } from '../../types/Lease';
// Theme
import { brand } from '../../../theme/theme';
import { useActionMessage } from '../../common/actionMessage/ActionMessage';
import { deleteLease, saveLease } from '../../../api/leaseService';

// Define the interface for the form's prop types
interface PostFormProps {
  open: boolean;
  handleClose: () => void;
  post?: Lease;
}
// Interface for Lease
//TODO Error Handling
const PostForm: React.FC<PostFormProps> = ({ open, handleClose, post }) => {
  const [user] = useAuthState(auth);
  const { showMessage } = useActionMessage();
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [initialFormData] = useState<any>({
    id: '',
    owner_id: user?.uid || '',
    email: user?.email || '',
    address: '',
    start_date: '',
    end_date: '',
    price: '',
    price_unit: 'Total',
    furnished: false,
    utilities_included_in_price: false,
    num_rooms_for_lease: '',
    num_property_bedrooms: '',
    are_roommates_aware: '',
    num_roommates_present_during_dublease: '',
    housing_gender: '',
    num_bathrooms: '',
    parking_option: '',
    pets_allowed: false,
    description: '',
    term_and_conditions_checkbox: false,
    image_urls: [],
    updatedAt: new Date(),
    amenities: [],
    verified: false,
  });
  const [formData, setFormData] = useState<any>(initialFormData);
  // Set the form data to the post data if it exists
  useEffect(() => {
    if (post) {
      //Convert DATA to DD/MM/YYYY
      // Convert start_date and end_date to "yyyy-MM-dd" format if they exist
      const formattedStartDate = post.start_date
        ? post.start_date.split('T')[0]
        : '';
      const formattedEndDate = post.end_date ? post.end_date.split('T')[0] : '';
      setFormData({
        ...post,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      });
      console.log('Post:', post);
    } else {
      setFormData({
        id: '',
        owner_id: user?.uid || '',
        email: user?.email || '',
        address: '',
        start_date: '',
        end_date: '',
        price: '',
        price_unit: 'Total',
        furnished: false,
        utilities_included_in_price: false,
        num_rooms_for_lease: '',
        num_property_bedrooms: '',
        are_roommates_aware: '',
        num_roommates_present_during_dublease: '',
        housing_gender: '',
        num_bathrooms: '',
        parking_option: '',
        pets_allowed: false,
        description: '',
        term_and_conditions_checkbox: false,
        image_urls: [],
        updatedAt: new Date(),
        amenities: [],
        verified: false,
      });
    }
  }, [post, user]);

  // Handle form submission
  const handleSubmit = async () => {
    if (!user) {
      signInWithGoogle();
      return;
    }

    setIsSubmitting(true);
    try {
      const result = await saveLease(formData, imageFiles);
      showMessage(result.message, 'success');
      handleClose();
      // TODO reload the page
    } catch (error) {
      showMessage('Error saving post. Please try again.', 'error');
    }
    setIsSubmitting(false);
  };
  // Delete the post and close the dialog
  const handleDeleteConfirm = async () => {
    if (post && user && user?.uid === post.owner_id) {
      try {
        const result = await deleteLease(post.id || '');
        showMessage(result.message, 'success');
        handleClose();
        // TODO reload the page
      } catch (error) {
        showMessage('Error deleting post. Please try again.', 'error');
      }
    }
  };

  // Handle form input changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
          maxHeight: '90vh', // Maximum height
          overflowY: 'auto', // Allow vertical scrolling
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
          p: 4,
          outline: 0, // Remove default focus outline
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-title"
          variant="h5"
          sx={{ color: brand[400] }}
          component="h2"
        >
          Dublease Form
        </Typography>
        <Divider variant="fullWidth" sx={{ mt: 2 }} />
        <Box component="form" noValidate sx={{ mt: 1 }}>
          {/* Form Inputs */}
          <FormInputs
            formData={formData}
            handleChange={handleChange}
            setFormData={setFormData}
          />
          {/* Image Upload */}
          <ImageUpload
            setImageFiles={setImageFiles}
            formData={formData}
            setFormData={setFormData}
          />
          {/* Terms & Conditions */}
          <TermsAndConditions
            checked={formData.termsAndConditionsCheckBox}
            onChange={handleChange}
          />
          {/* Clear & Submit */}
          <SubmitButton
            isSubmitting={isSubmitting}
            onReset={() => setFormData({ ...initialFormData, imageUrls: [] })}
            onSubmit={handleSubmit}
            onDelete={() => setDeleteDialogOpen(true)}
            post={post}
          />

          {/* Delete Confirmation Dialog */}
          <ConfirmationDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onConfirm={handleDeleteConfirm}
            title="Are you sure you want to delete this post? Deleting the post will also delete the bids associated with it."
            confirmText="Delete"
            cancelText="Cancel"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PostForm;
