import React from 'react';
import { Box, Typography, Avatar, Tooltip, IconButton } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { formatChatTimestamp } from '../utils/formatTimestamps';
import { brand } from '../../theme/theme';
// Define the props for the BidderCard component
interface BidCardProps {
  bidderName: string;
  bidderProfilePic: string;
  lastMessage: string;
  mostRecentBid: string;
  timestamp: string;
  onClick: () => void;
}

const BidCard: React.FC<BidCardProps> = ({
  bidderName,
  bidderProfilePic,
  lastMessage,
  mostRecentBid,
  timestamp,
  onClick,
}) => {
  const formattedTime = formatChatTimestamp(timestamp);

  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'divider',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: 'rgba(156, 204, 252, 0.1)',
        },
        overflow: 'hidden',
        width: '100%', // Make sure the card takes up the full width of the parent container
      }}
    >
      {/* Profile Picture */}
      <Avatar
        src={bidderProfilePic}
        alt="Bidder Profile"
        sx={{ width: 56, height: 56, mr: 2 }}
      />

      {/* Bid and Message Info */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {/* Name and Timestamp Row */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              flexGrow: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {bidderName}
          </Typography>

          {/* Timestamp */}
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              flexShrink: 0,
              whiteSpace: 'nowrap',
              ml: 2,
            }}
          >
            {formattedTime.toString()}
          </Typography>
        </Box>

        {/* Last Message */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          {lastMessage}
        </Typography>

        {/* Most Recent Bid */}
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
            color: brand[500],
          }}
        >
          {mostRecentBid}
        </Typography>
      </Box>

      {/* Chat Icon */}
      <Tooltip title="Chat">
        <IconButton size="small">
          <ChatIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default BidCard;
