import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { brand } from '../../../../theme/theme'; // Replace with your theme file if needed

interface TermsModalProps {
  open: boolean;
  handleClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
          height: '90vh',
          maxHeight: '90vh', // Maximum height
          overflowY: 'auto', // Allow vertical scrolling
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
          p: 4,
          outline: 0, // Remove default focus outline
        }}
      >
        {/* Header with Title and Close Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', color: brand[500] }}
          >
            Dublease Terms and Conditions
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Scrollable Content */}
        <Box
          sx={{
            maxHeight: '90vh', // Adjust height for the scroll
            overflowY: 'auto',
            pr: 2, // Padding for scrollbar
          }}
        >
          <Typography variant="body1" paragraph>
            <strong>Welcome to Dublease!</strong> By accessing or using the
            Dublease website (the "Service"), you agree to be bound by these
            Terms and Conditions. If you do not agree with these Terms, do not
            use the Service.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            1. Description of Service
          </Typography>
          <Typography variant="body1" paragraph>
            Dublease provides a platform for users to connect for subleasing
            opportunities. We facilitate connections between sublessors (those
            looking to sublease their property) and subletters (those seeking a
            sublease). Dublease is not responsible for property management or
            lease agreements between users and their landlords.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            2. User Accounts
          </Typography>
          <Typography variant="body1" paragraph>
            You must create an account to access certain features of the
            Service. You are responsible for maintaining the confidentiality of
            your account information and all activities that occur under your
            account. By providing information during account creation, you
            confirm that it is accurate to the best of your knowledge.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            3. Listings and Legal Responsibility
          </Typography>
          <Typography variant="body1" paragraph>
            Tenants can create listings for properties they wish to sublease,
            and subletters can browse and apply for these listings. By creating
            a listing or engaging in a sublease, you confirm that you are
            legally allowed to sublease your property and have obtained
            necessary permissions from your property owner. Dublease is not
            liable for any legal trouble arising between you and your property
            owner for subleasing without proper authorization.
          </Typography>
          <Typography variant="body1" paragraph>
            By clicking to accept these Terms and Conditions, you assume all
            legal responsibility related to your lease agreement and
            interactions with your property owner.
          </Typography>
          <Typography variant="body1" paragraph>
            Dublease simply facilitates connections; we do not intervene or take
            responsibility for your lease or sublease terms.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            4. Transactions and Fees
          </Typography>
          <Typography variant="body1" paragraph>
            Dublease facilitates payments between sublessors and subletters. The
            subletter will be charged a $50 flat fee, and the sublessor will be
            charged a $100 flat fee plus a 5% fee on the total sublease
            transaction.
          </Typography>
          <Typography variant="body1" paragraph>
            Important: Any disputes related to the original lease agreement with
            the property owner are outside the scope of Dublease’s
            responsibilities.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            5. Cancellations, Terminations, and Refunds
          </Typography>
          <Typography variant="body1" paragraph>
            Refunds for cancellations or other contract terminations are managed
            on a case-by-case basis. To request a refund, please contact our
            support team via the Contact Us page.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            6. User Conduct
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to use the Service in a lawful manner and refrain from any
            prohibited or harmful activities. Dublease is not responsible for
            your interactions with other users.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            7. In-App Notifications and Document Updates
          </Typography>
          <Typography variant="body1" paragraph>
            You will be notified of important updates to these Terms and
            Conditions, Privacy Policy, and other key documents via the
            Notifications tab in your account’s Inbox. Users are required to
            acknowledge updates when prompted.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            8. Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            Our Privacy Policy explains how we handle your personal data. By
            using the Service, you consent to the collection and use of your
            information as described in the Privacy Policy.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            9. Legal Disclaimers and Limitations of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            Dublease is not liable for any legal disputes or issues you may
            encounter regarding your original lease agreement with your
            landlord. You are solely responsible for ensuring compliance with
            your lease and any obligations to your property owner.
          </Typography>
          <Typography variant="body1" paragraph>
            Additionally, Dublease is not responsible for any indirect,
            incidental, or consequential damages arising from the use of our
            Service.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            10. Illegal Use and False Listings
          </Typography>
          <Typography variant="body1" paragraph>
            By using Dublease, you confirm that the account you create and any
            property listings you post are legitimate and that you are the
            rightful lister or have full legal authorization to list the
            property.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            11. Changes to These Terms and Conditions
          </Typography>
          <Typography variant="body1" paragraph>
            We may revise these Terms and Conditions periodically. Any updates
            will be communicated through the Notifications tab in the Inbox and
            by posting the new Terms on the Service. Your continued use of the
            platform after changes have been made constitutes your acceptance of
            the new Terms.
          </Typography>

          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, ml: 2 }}>
            12. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have questions about these Terms and Conditions, please reach
            out to us through the Contact Us page.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsModal;
