import { auth } from '../config/firebase-config';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Save a new lease or update an existing one
export const saveLease = async (formData: any, imageFiles: File[]): Promise<any> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const form = new FormData();
    form.append('postData', JSON.stringify(formData));
    imageFiles.forEach((file) => form.append('images', file));

    const response = await fetch(`${API_BASE_URL}/api/posts/save`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      body: form,
    });

    if (!response.ok) {
      throw new Error(`Failed to save lease: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error saving lease:', error);
    throw error;
  }
};

// Delete a lease
export const deleteLease = async (postId: string): Promise<any> => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('User not authenticated');

    const idToken = await user.getIdToken();

    const response = await fetch(`${API_BASE_URL}/api/posts/delete/${postId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to delete lease: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting lease:', error);
    throw error;
  }
};

// Fetch lease details for a specific lease (used: Inbox -> Incoming Bids -> LeaseDetailsModal.tsx)
export const getLease = async (leaseId: string): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/posts/getLease?id=${leaseId}`, {
        method: 'GET',
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch lease details: ${response.statusText}`);
    }

    const data = await response.json();
    return data.lease || {};
  } catch (error) {
    console.error('Error fetching lease details:', error);
    throw error;
  }
};

// Will fetch all leases from the backend to display (used: leaseDisplay)
export const getLeases = async (
  page: number, 
  filters: { 
    startDate: string | null; 
    endDate: string | null; 
    location: string | null; 
    numBeds: number | ""; 
    numRoommates: number | ""; 
    sortPrice: string; 
  }): 
  Promise<any> => {
    try {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        startDate: filters.startDate || '',
        endDate: filters.endDate || '',
        location: filters.location || '',
        numBeds: filters.numBeds ? filters.numBeds.toString() : '',
        numRoommates: filters.numRoommates ? filters.numRoommates.toString() : '',
        sortPrice: filters.sortPrice,
        owner: auth.currentUser?.uid || '',
      });

      const response = await fetch(`${API_BASE_URL}/api/posts/getLeases?${queryParams}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch lease details: ${response.statusText}`);
      }

      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error('Error fetching leases:', error);
      throw new Error('Unable to fetch leases');
      //return { posts: [], totalCount: 0 };
    }
  };


  // Listen to lease events with sockets (create, update, delete)
// export const subscribeToLeaseEvents = (
//     onCreate: (lease: Lease) => void,
//     onUpdate: (lease: Lease) => void,
//     onDelete: (leaseId: string) => void
//   ) => {
//     // Subscribe to lease events from the backend
//     socket.on('lease:create', (message: string) => {
//       const newLease = JSON.parse(message).data;
//       onCreate(newLease);
//     });
  
//     socket.on('lease:update', (message: string) => {
//       const updatedLease = JSON.parse(message).data;
//       onUpdate(updatedLease);
//     });
  
//     socket.on('lease:delete', (message: string) => {
//       const deletedLeaseId = JSON.parse(message).lease_id;
//       onDelete(deletedLeaseId);
//     });
  
//     // Return a cleanup function to unsubscribe from events
//     return () => {
//       socket.off('lease:create');
//       socket.off('lease:update');
//       socket.off('lease:delete');
//     };
//   };