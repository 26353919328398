import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Skeleton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VerifiedUser } from '@mui/icons-material';
import LeaseDetailsTab from './tabs/LeaseDetailsTab';
import UserDetailsTab from './tabs/UserDetailTab';
import IncomingBids from '../bids/IncomingBids';
import PlaceBid from '../bids/PlaceBid';
import Chat from '../chat/Chat';
import { Lease } from '../types/Lease';
import { auth } from '../../config/firebase-config';
import { getLease } from '../../api/leaseService';

interface LeaseDetailsModalProps {
  open: boolean;
  handleClose: () => void;
  leaseId: string;
  defaultTabIndex?: number;
}

const LeaseDetailsModal = ({
  open,
  handleClose,
  leaseId, //if id is passed in, we need to fetch the post since we don't have the lease object
  defaultTabIndex = 0,
}: LeaseDetailsModalProps) => {
  const [loading, setLoading] = useState(false);
  const [lease, setLease] = useState<Lease | undefined>(undefined);
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  const [chatId, setChatId] = useState('');
  const [bidderId, setBidderId] = useState('');
  const [ownerId, setOwnerId] = useState('');

  useEffect(() => {
    const fetchLease = async () => {
      setLoading(true);
      const minimumLoadingTime = 1000; // Minimum loading time in ms (1 second)
      const startTime = Date.now();

      try {
        const response = await getLease(leaseId);
        setLease(response);
      } catch (error) {
        console.error('Error fetching lease details:', error);
      } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = minimumLoadingTime - elapsedTime;

        // If remainingTime is positive, wait for that duration before stopping loading
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0));
      }
    };
    fetchLease();
  }, [leaseId]);

  useEffect(() => {
    // Update tabIndex when modal opens based on the passed defaultTabIndex
    setTabIndex(defaultTabIndex);
  }, [open, defaultTabIndex]);

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    console.log(lease);
    setTabIndex(newIndex);
  };

  const goToChat = (chat_id: string, owner_id: string, bidder_id: string) => {
    setChatId(chat_id);
    setOwnerId(owner_id);
    setBidderId(bidder_id);
    setTabIndex(3);
  };

  if (!lease) return null;
  // if (loading) {
  //   return <CircularProgress />;
  // }
  // Determine the label for the second tab based on ownership
  const isOwner = lease.owner_id === auth.currentUser?.uid;
  const incomingOrPlaceBids = isOwner
    ? 'Incoming Bids & Chat'
    : 'Place Bid & Chat';

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="lease-details-title"
    >
      <Box
        sx={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: 'divider',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '50%' }, // Responsive width
          maxHeight: '90vh', // Maximum height
          overflowY: 'auto', // Allow vertical scrolling
          bgcolor: 'background.paper',
          boxShadow: `0 0 100px 10px rgb(156, 204, 252)`, // Adds a blue glow
          p: 4,
          outline: 0, // Remove default focus outline
        }}
      >
        {/* X Close Button */}
        <IconButton
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            zIndex: 1,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {loading ? (
          // Render Skeletons when loading
          <>
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={400}
              sx={{ borderRadius: '10px', mb: 2 }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              height={30}
              sx={{ mb: 1, width: '80%' }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              height={20}
              sx={{ mb: 1, width: '50%' }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={250}
              sx={{ borderRadius: '10px', mb: 2 }}
            />
          </>
        ) : (
          <>
            <Typography
              id="lease-details-title"
              variant="h6"
              component="h2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600,
                fontSize: 24,
                color: 'primary.main',
                marginBottom: 2,
              }}
            >
              {lease.address}
              {lease.verified && (
                <Tooltip title="User is verified">
                  <VerifiedUser
                    sx={{
                      color: '#1976d2',
                      fontSize: '2rem',
                      verticalAlign: 'text-top',
                      ml: 1,
                    }}
                  />
                </Tooltip>
              )}
            </Typography>

            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Lease details tabs"
              sx={{ marginBottom: 2 }}
            >
              <Tab sx={{ fontSize: '14px' }} label="Lease Details" />
              <Tab sx={{ fontSize: '14px' }} label="User Details" />
              <Tab sx={{ fontSize: '14px' }} label={incomingOrPlaceBids} />
            </Tabs>

            {tabIndex === 0 && <LeaseDetailsTab lease={lease} />}
            {tabIndex === 1 && <UserDetailsTab lease={lease} />}
            {tabIndex === 2 && (
              <Box>
                {isOwner ? (
                  // Content for "Check Bids" tab
                  <IncomingBids lease={lease} goToChat={goToChat} />
                ) : (
                  // Content for "Biddings" tab
                  <PlaceBid lease={lease} goToChat={goToChat} />
                )}
              </Box>
            )}
            {tabIndex === 3 && (
              <Chat
                chatId={chatId}
                ownerId={ownerId}
                bidderId={bidderId}
                leaseId={lease?.id || ''}
              />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default LeaseDetailsModal;
