import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { MenuItem } from '@mui/material';
import { Divider } from '@mui/material';
import PostForm from '../../dubleases/form/PostForm';
import Profile from '../../profile/Profile';
import AuthControl from '../../auth/AuthControl';
import '../../../theme/global.css';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase-config';
import { brand } from '../../../theme/theme';
import TermsModal from '../footer/sections/TermsConditionsModal';
import InboxModal from '../../inbox/Inbox';

function Navbar() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [PostFormModalOpen, setPostFormModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [signInWithGoogle] = useSignInWithGoogle(auth);

  const backToHomeScreen = () => {
    navigate('/'); // Navigate back to home page
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleProfileModalOpen = () => {
    if (user) {
      setProfileModalOpen(true);
    } else {
      signInWithGoogle(); // Sign in with Google if user is not signed in
    }
  };
  const handleTermsModalOpen = () => {
    setTermsModalOpen(true);
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          borderRadius: '999px',
          border: '1px solid',
          borderColor: 'divider',
          top: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          //backgroundColor: 'transparent',
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          backdropFilter: 'blur(24px)',
          boxShadow: 5,
          width: { xs: '90%', md: '70%' },
          marginTop: 2,
        }}
      >
        <Container>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row', // Center content vertically
            }}
          >
            {/* Branding with subtitle */}
            <Box
              onClick={backToHomeScreen}
              sx={{
                display: 'inline-block',
                cursor: 'pointer', // Show pointer cursor on hover
                '&:hover': {
                  opacity: 0.8, // Add subtle hover effect
                },
              }}
            >
              <Typography variant="h5" sx={{ color: brand[500] }}>
                dublease
              </Typography>
              <Typography variant="h6" color="inherit">
                subleasing for college students
              </Typography>
            </Box>

            {/* Menu items for desktop */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
              <Button
                onClick={() => setPostFormModalOpen(true)}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Make A Post
              </Button>
              <Button
                onClick={handleProfileModalOpen}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Profile
              </Button>
              <Button
                onClick={() => setInboxOpen(true)}
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Inbox
              </Button>
              <Button
                component={Link}
                to="/FAQS&ContactUs"
                className="custom-gray-button"
                sx={{ fontSize: '15px' }}
              >
                Faq & Contact Us
              </Button>
              <Divider orientation="vertical" flexItem />
              <AuthControl />
            </Box>

            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton color="inherit" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Box sx={{ width: 250 }}>
                  <MenuItem
                    onClick={() => setPostFormModalOpen(true)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    Make A Post
                  </MenuItem>
                  <MenuItem
                    onClick={handleProfileModalOpen}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => setInboxOpen(true)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    Inbox
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/AboutUs"
                    onClick={toggleDrawer(false)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    About Us
                  </MenuItem>
                  <MenuItem
                    to="/FAQS&ContactUs"
                    component={Link}
                    onClick={toggleDrawer(false)}
                    className="custom-gray-button"
                    sx={{ fontSize: '15px' }}
                  >
                    FAQs & Contact Us
                  </MenuItem>
                  <MenuItem
                    onClick={handleTermsModalOpen} // Open the modal for Terms
                  >
                    Terms & Conditions
                  </MenuItem>
                  <AuthControl />
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <PostForm
        open={PostFormModalOpen}
        handleClose={() => setPostFormModalOpen(false)}
      />
      <Profile
        open={profileModalOpen}
        handleClose={() => setProfileModalOpen(false)}
      />
      <TermsModal
        open={termsModalOpen}
        handleClose={() => setTermsModalOpen(false)}
      />
      <InboxModal open={inboxOpen} handleClose={() => setInboxOpen(false)} />
    </>
  );
}

export default Navbar;
