// OutgoingBids.tsx
import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getOwnerLeases } from '../../api/interactionService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/firebase-config';
import IncomingBids from './IncomingBids';
import { brand } from '../../theme/theme';

interface AllIncomingBidsProps {
  goToLease: (lease: any, leaseId: string) => void;
}

const AllIncomingBids: React.FC<AllIncomingBidsProps> = ({ goToLease }) => {
  const [user] = useAuthState(auth);
  const [ownerLeases, setOwnerLeases] = useState([]);

  useEffect(() => {
    const fetchBidderLeases = async () => {
      if (!user) return;
      try {
        const leasesBidOn = await getOwnerLeases(user.uid);
        setOwnerLeases(leasesBidOn);
      } catch (err) {
        console.error('Error fetching bidder leases:', err);
      }
    };

    fetchBidderLeases();
  }, [user]);

  return (
    <Box>
      {ownerLeases.length === 0 && (
        <Typography>No incoming bids available.</Typography>
      )}
      {ownerLeases.map((lease: any) => (
        <Accordion
          key={lease.id}
          disableGutters // Removes padding
          elevation={0} // Removes box shadows
          sx={{
            '&:before': {
              display: 'none', // Removes the default underline
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${lease.id}`}
            id={`panel-header-${lease.id}`}
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center content horizontally
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: brand[500],
                textAlign: 'center', // Center text
              }}
            >
              {lease.address}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <IncomingBids
              lease={lease}
              goToChat={() => goToLease(lease, lease.id)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AllIncomingBids;
